import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { catchError, finalize, map, tap } from 'rxjs';

import { Customer, CustomerBase } from '../customer/customer.types';
import { CUSTOMERS_PER_REQUEST } from './customers.constants';
import { CustomersStore } from './customers.store';
import { CustomerChurchGroup } from './customers.types';

export type CustomersListResponse = {
	customers: CustomerBase[];
	totalCustomers: number;
	canAddCustomer: boolean;
	canAddGroup: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class CustomersService {
	readonly #customersStore = inject(CustomersStore);
	#apiService = inject(ApiService);

	isLoading = signal<boolean>(false);

	getCustomer(customerId: string) {
		this.isLoading.set(true);

		return this.#apiService.get<Customer>(`/customer/${customerId}`).pipe(
			tap((data) => {
				this.#customersStore.addCustomerDetail(data);
			}),
			finalize(() => {
				this.isLoading.set(false);
			}),
		);
	}

	getChurchGroupByCustomer(customerId: string) {
		return this.#apiService.get<CustomerChurchGroup[]>(`/churchgroup/getall/${customerId}`).pipe(
			tap((data) => {
				this.#customersStore.addChurchGroups(data);
			}),
		);
	}

	getAllCustomers(access?: string, page: number = 1) {
		this.isLoading.set(true);

		return this.#apiService
			.get<CustomersListResponse>(`/customer?page=${page}&numberPerPage=${CUSTOMERS_PER_REQUEST}`, {
				params: { access },
			})
			.pipe(
				finalize(() => this.isLoading.set(false)),
				tap((data) => {
					this.#customersStore.addCustomersList(data.customers || []);
					this.#customersStore.setLoadedCustomers(data.customers.length);
					this.#customersStore.setTotalCustomers(data.totalCustomers);
				}),
				map((data) => data.customers),
				catchError(() => {
					throw new Error('Error getting customers list');
				}),
			);
	}

	getWorkOrderByCustomer(customerId: string) {
		return this.#apiService
			.get<number[]>(`/workorder/getworkordersbycustomer/${customerId}`)
			.pipe(catchError(() => []));
	}

	updateCustomer(customerCode: string, customer: Record<string, unknown>) {
		return this.#apiService.put(`/customer/${customerCode}`, customer).pipe(
			catchError(() => {
				throw new Error('Error updating customer notification preferences');
			}),
		);
	}
}
